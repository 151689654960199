/* Info Card */
.info-card {
  background: linear-gradient(135deg, #ffffff, #ffffff); border-radius: 16px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  padding: 32px;
  max-width: 450px;
  height: 700px;
  margin: 20px auto;
  text-align: center; /* Align text to the center */
  perspective: 1000px; /* Perspective for 3D effect */
  overflow: hidden; /* Prevent overflow */
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s ease-in-out; /* Smooth transition for flip */
  transform-style: preserve-3d; /* Preserve 3D effect */
}

.card-inner.flipped {
  transform: rotateY(180deg); /* Flip effect on click */
}

/* Front and Back Sides */
.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hide back side when not flipped */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease; /* Smooth background transition */
}

/* Back Side */
.card-back {
  background-color: #ffffff; /* Background color for back side */
  transform: rotateY(180deg); /* Rotate back side */
}

/* Logo Section */
.logo-div {
  margin-bottom: 30px;
}

.logo-div img {
  width: 50%;
  height: auto;
  object-fit: contain;
}

/* Profile Picture */
.profile img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 0px solid #ddd;
  margin-bottom: 20px;
}

/* Name and Designation */
.name {
  font-size: 24px;
  font-weight: 700;
  color: #0a0a0a;
  margin-bottom: 0px;
}

.dev {
  font-size: 20px;
  color: #141313;
  margin-bottom: 10px;
}
.blood-g{
  font-size: 15px;
  color: #000000;
  font-weight: 600;
}
/* Contact Details */
.contact-details {
  margin: 20px;
}

.contact-details p {
  font-size: 16px;
  margin: 12px 0;
  color: #555555;
  display: flex;
  align-items: center;
  gap: 30px;
}

.contact-details a {
  color: #000000;
  text-decoration: none;
  font-weight: 600;
}

.contact-details a:hover {
  text-decoration: underline;
  color: #5c4f5a;
}

/* Address Section */
.address a {
  color: #000000;
  font-size: 16px;
  display: inline-block;
  margin-top: 0px;
}


.emp-description{
  font-size: 16px;
  color: #000000;
}

/* Social Media Links */
.tags {
  margin-top: 20px;
}

.tags a {
  font-size: 22px;
  margin: 0 12px;
  color: #555555;
  text-decoration: none;
  transition: color 0.3s ease;
}

.tags a:hover {
  color: #e251ff;
}

/* Responsive Design */
@media (max-width: 480px) {
  .info-card {
    padding: 16px;
  }

  .profile img {
    width: 90px;
    height: 90px;
  }

  .name {
    font-size: 20px;
  }

  .dev {
    font-size: 16px;
  }

  .contact-details p {
    font-size: 14px;
  }

  .tags a {
    font-size: 18px;
  }
}