.section-full {
  padding: 110px 0 80px;
}

.portfolio-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.portfolio-item {
  flex: 0 0 30%; /* 3 columns */
  margin-bottom: 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.portfolio-box {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  padding: 20px;
  min-height: 300px;
  position: relative;
  transition: transform 0.3s ease;
}

.portfolio-item:hover .portfolio-box {
  transform: translateY(-10px);
  box-shadow: 0 12px 30px rgba(255, 0, 179, 0.2);
}

.client-img {
  margin-bottom: 15px;
  min-height: 160px;
  display: grid;
  align-content: center;
}

.project-image {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.client-img:hover .project-image {
  transform: scale(1.1);
}

.sx-portfolio-title {
  font-size: 1.5em;
  margin: 10px 0;
  min-height: 100px;
}

.sx-portfolio-description {
  font-size: 1em;
  color: #666;
  margin-bottom: 15px;
}

.sx-portfolio-btn {
  margin-top: 10px;
}

.site-button {
  background-color: #831379;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.site-button:hover {
  background-color: #ff24bd;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .portfolio-item {
    flex: 0 0 45%; /* 2 columns for tablets */
  }
}

@media (max-width: 768px) {
  .portfolio-item {
    flex: 0 0 100%; /* 1 column for mobile */
  }

  .sx-portfolio-title {
    font-size: 1.2em; /* Smaller title font size */
  }

  .sx-portfolio-description {
    font-size: 0.9em; /* Smaller description font size */
  }

  .site-button {
    padding: 8px 16px; /* Smaller button padding */
  }
}