/* General Container */

  
  /* Language Toggle (on top of carousel) */
  .language-toggle1 {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 0.5rem;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0.375rem;
    padding: 0.5rem 1rem;
  }
  
  .language-toggle1 button {
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    font-weight: bold;
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
  }
  
  .language-toggle1 button:focus {
    outline: 2px solid #3182ce;
  }
  
  .language-toggle1 button:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .language-toggle1 button.active {
    background-color: #3182ce;
    color: white;
    transform: scale(1.05);
  }
  
  /* Carousel (Swiper) */
  .carousel-container {
    position: relative;
    width: 50%;
    height: 10vh;
    margin-bottom: 2rem;
    border-radius: 0.375rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .carousel-container .swiper-slide img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.4s ease-in-out;
  }
  
  .carousel-container .swiper-slide:hover img {
    transform: scale(1.1);
  }
  
  
  
  /* Responsive Styles */
  @media (min-width: 768px) {
    .language-toggle1 {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      gap: 0.5rem;
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 0.375rem;
      padding: 0.5rem 1rem;
    }
    .projects-container {
      grid-template-columns: repeat(2, 1fr);
    }
  
   
    .project-card img {
      height: 300px;
    }
  
   
  }
  
  @media (min-width: 1024px) {
    .projects-container {
      grid-template-columns: repeat(3, 1fr);
    }
  
}