/* InquiryForm.css */

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 1000;
}

.popup-overlay.active {
    opacity: 1;
    visibility: visible;
}

.popup-content {
    background: #fff;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-30px);
    transition: transform 0.3s ease;
}

.popup-overlay.active .popup-content {
    transform: translateY(0);
}

.close-button {
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

.contact-home1-right {
    padding: 20px;
    border-radius: 10px;
    background: linear-gradient(135deg, #f0f4f8, #d9e2ec);
}

.input {
    position: relative;
    margin-bottom: 20px;
}

.input-animate input,
.input-animate textarea {
    width: 100%;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.input-animate input:focus,
.input-animate textarea:focus {
    border-color: #831379;
    box-shadow: 0 0 5px rgba(131, 19, 121, 0.5);
}

.site-button {
    background-color: #831379;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.site-button:hover {
    background-color: #ff24bd;
    transform: translateY(-2px);
}