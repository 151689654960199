.portfolio-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 60px; /* Space between projects */
    justify-content: center; /* Center projects */
    padding: 20px;
    height: fit-content;
  }

  /* @keyframes zoomInOut {
    0% { transform: scale(1); box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); }
    50% { transform: scale(1.1); box-shadow: 0 8px 12px rgba(0, 0, 0, 0.7); }
    100% { transform: scale(1); box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); }
} */
  
  .project-card {
    /* animation: zoomInOut 8s ease-in-out infinite; */
    flex: 1 1 calc(33.33% - 40px); /* Three cards in a row */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
  }
  .project-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .project-title {
    margin: 10px 0;
    font-size: 1.3rem;
    color: #fffefe;
   
  }
  .project-title1 {
    margin: 10px 0;
    font-size: 1.0rem;
    color: #fffefe;
   
  }
  .title-p{

    background-color: black;
    padding: 5px;
  }
  #title-p1{
    color: white;
  }
  @media (max-width: 1080px) {
    .project-card {
      flex: 1 1 calc(100% - 20px); /* One card per row on smaller screens */
    }
  }
  @media (max-width: 768px) {
    .project-card {
      flex: 1 1 calc(100% - 20px); /* One card per row on smaller screens */
    }
  }
    