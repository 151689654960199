.main-section{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.work-section-content {
    max-width: 1200px;
    
    
    padding: 20px;
  }
  
  .work-portfolio-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    gap: 20px; /* Add gap between items */
    justify-content: center;
    align-items: center;
  }
  
  .work-portfolio-item {
    position: relative;
    overflow: hidden; /* Hide overflow for hover effect */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    transition: transform 0.3s ease; /* Smooth scaling on hover */
  }
  
  .work-portfolio-item:hover {
    transform: scale(1.05); /* Scale up on hover */
  }
  
  .work-client-img {
    position: relative;
    border-radius: 10px; /* Match rounded corners */
    overflow: hidden; /* Ensure image respects rounded corners */
  }
  
  .work-project-image {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    display: block;
    transition: transform 0.3s ease; /* Smooth transition for image */
  }
  
  .work-client-img:hover .work-project-image {
    transform: scale(1.1); /* Slightly zoom image on hover */
  }
  
  .work-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Darker semi-transparent background */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease; /* Smooth transition */
  }
  
  .work-portfolio-item:hover .work-overlay {
    opacity: 1; /* Show overlay on hover */
  }
  
  .work-portfolio-title {
    font-size: 1.5rem; /* Adjust font size as needed */
    text-align: center;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Add text shadow for better readability */
  }